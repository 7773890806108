import { SurgaProperty } from "./surgaapi/surgaPropertyModel";
import { searchHelperComp } from "./../searchHelperComp";
export class AssetFile {
  public featureListings: SurgaProperty[];
  public propertyType: PropertyType[];
  constructor(xml: string = "") {
    this.featureListings = [];
    this.propertyType = [];
    this.initiateXml(xml);
  }

  private async initiateXml(xml: string) {
    if (xml !== "") {
      const xmlObject = this.parseXml(xml, []).SurgaAssetData;
      for (const property of xmlObject.FeatureListings.Listing) {
        const surgaProperty: SurgaProperty = new SurgaProperty();
        surgaProperty.setupFeatured(property);
        this.featureListings.push(surgaProperty);
      }
      for (const propertyType of xmlObject.PropertyTypes.PropertyType) {
        this.propertyType.push(new PropertyType(propertyType));
      }
      // NEW SEARCH FUNCTION
      searchHelperComp().initiate();
      // initiate stored addresses
      searchHelperComp().add().rawRegion(xmlObject.Regions.RegionName);
      searchHelperComp().add().rawAddress(xmlObject.Addresses.Address);
      searchHelperComp().add().rawSuburb(xmlObject.Suburbs.Suburb);
      searchHelperComp().localStore().save();
    }
  }

  private parseXml(xml: string, arrayTags: string[]): any {
    let dom = null;
    if (window.DOMParser) {
      dom = new DOMParser().parseFromString(xml, "text/xml");
    } else if (window.ActiveXObject) {
      dom = new ActiveXObject("Microsoft.XMLDOM");
      dom.async = false;
      if (!dom.loadXML(xml)) {
        throw new Error(dom.parseError.reason + " " + dom.parseError.srcText);
      }
    } else {
      throw new Error("cannot parse xml string!");
    }

    function parseNode(xmlNode: any, result: Record<string, any>) {
      if (xmlNode.nodeName === "#text") {
        const v = xmlNode.nodeValue;
        if (v && v.trim()) {
          result["_parameter"] = v;
        }
        return;
      }

      const jsonNode: Record<string, any> = {};
      const existing = result[xmlNode.nodeName];
      if (existing) {
        if (!Array.isArray(existing)) {
          result[xmlNode.nodeName] = [existing, jsonNode];
        } else {
          result[xmlNode.nodeName].push(jsonNode);
        }
      } else {
        if (arrayTags && arrayTags.indexOf(xmlNode.nodeName) !== -1) {
          result[xmlNode.nodeName] = [jsonNode];
        } else {
          result[xmlNode.nodeName] = jsonNode;
        }
      }

      if (xmlNode.attributes) {
        for (const attribute of xmlNode.attributes) {
          jsonNode[attribute.nodeName] = attribute.nodeValue;
        }
      }
      for (const node of xmlNode.childNodes) {
        parseNode(node, jsonNode);
      }
    }

    const result = {};
    for (const node of dom.childNodes) {
      parseNode(node, result);
    }
    return result;
  }
  public JSONcopy(jsobject: any): void {
    for (let listing of jsobject.featureListings) {
      let property: SurgaProperty = new SurgaProperty();
      property.JSONCopy(listing);
      this.featureListings.push(property);
    }
    for (let type of jsobject.propertyType) {
      let newType: PropertyType = new PropertyType(undefined);
      newType.JSONCopy(type);
      this.propertyType.push(type);
    }
  }
}

export class PropertyType {
  public code: string = "";
  public name: string = "";
  constructor(propertyType: any) {
    if (propertyType === undefined) {
      return;
    }
    this.code = propertyType.propcode;
    this.name = propertyType._parameter;
  }
  public JSONCopy(jsobject: any) {
    this.code = jsobject.code;
    this.name = jsobject.name;
  }
}
