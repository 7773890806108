//stores data for final search results. Used to generate URL.
export class SearchResultModel {
  public _suburb: string;
  public _street: string;
  public _postcode: string;
  public _state: string;
  public _siteName: string;
  public _city: string;
  public searchText: string;
  // eslint-disable-next-line no-undef
  [key: string]: string;
  constructor() {
    this._suburb = "";
    this._street = "";
    this._postcode = "";
    this._state = "";
    this._siteName = "";
    this._city = "";
    this.searchText = "";
  }
  // @ts-ignore
  public JSONcopy(jsobject: any): void {
    this._suburb = jsobject._suburb;
    this._street = jsobject._street;
    this._postcode = jsobject._postcode;
    this._state = jsobject._state;
    this._siteName = jsobject._siteName;
    this._city = jsobject._city;
    this.searchText = jsobject.searchText;
  }
  get suburb(): string {
    return this.sanitize(this._suburb);
  }
  set suburb(value: string) {
    if (value !== null || value !== undefined) {
      this._suburb = value;
    }
  }
  get street(): string {
    return this.sanitize(this._street);
  }
  set street(value: string) {
    if (value !== null || value !== undefined) {
      this._street = value;
    }
  }
  get postcode(): string {
    return this.sanitize(this._postcode);
  }
  set postcode(value: string) {
    if (value !== null || value !== undefined) {
      this._postcode = value;
    }
  }
  get state(): string {
    return this.sanitize(this._state);
  }
  set state(value: string) {
    if (value !== null || value !== undefined) {
      this._state = value;
    }
  }
  get siteName(): string {
    return this.sanitize(this._siteName);
  }
  set siteName(value: string) {
    if (value !== null || value !== undefined) {
      this._siteName = value;
    }
  }
  get city(): string {
    return this.sanitize(this._city);
  }
  set city(value: string) {
    if (value !== null || value !== undefined) {
      this._city = value;
    }
  }
  // Add Suburb Multiple
  // @ts-ignore
  public AddSuburbMultiple(value: string): void {
    if (value !== null || value !== undefined) {
      if (this._suburb.length > 0)
      {
        this._suburb += ("," + value)
      } else {
        this._suburb = value;
      }
    }
  }
  // @ts-ignore
  public clone(searchResult: SearchResultModel): string {
    this._suburb = searchResult._suburb;
    this._street =  searchResult._street;
    this._postcode =  searchResult._postcode;
    this._state =  searchResult._state;
    this._siteName =  searchResult._siteName;
    this._city =  searchResult._city;
    this.searchText =  searchResult.searchText;
  }
  // @ts-ignore
  public toString(): string {
    let finalString: string = "";
    // check if there is a street and sitename. if there is not return empty string.
    if (this._siteName.trim() === "" && this._street.trim() === "") {
      return "";
    }
    if (this._siteName.trim() !== "") {
      finalString = finalString + this._siteName + ", ";
    }
    if (this._street.trim() !== "") {
      finalString = finalString + this._street + ", ";
    }
    if (this._suburb.trim() !== "") {
      finalString = finalString + this._suburb;
    } else {
      finalString = finalString.substring(0, finalString.length - 2);
    }
    return finalString;
  }
  // @ts-ignore
  private sanitize(inputString: string): string {
    if (inputString) {
      inputString = inputString.toLowerCase();
      return inputString;
    }
    return "";
  }
}
