import { reactive, ref } from "vue";
import { getLocaleCurrency, getLocaleForLease, getPAorPAX } from "./localeComp";
import { SurgaProperty } from "./models/surgaapi/surgaPropertyModel";
import { surgaSearch } from "./search/searchAPIManagerComp";
import { getAreaUOM,  isLeaseProperty,  isSaleProperty,  manageLandRange,  manageListingTypeString,  managePriceRange } from "./listingCommonComp";
const currentListing = reactive({
  propertyId: "" as string,
  propertyDetails: {} as SurgaProperty
});
// determines state of component.
// idle: system is inactive.
// sent: API sent and waiting for reply.
// ready: API data is ready to be retrieved.
// error: error has occurred with API.
const state = ref("idle" as string);

let propertyId = "";
const urlWebId: string = getWebId();
// retrieves the getWebId
function getWebId(): string {
  const url: URL = new URL(location.href);
  const lastSegment: string = url
    .toString()
    .substring(url.toString().lastIndexOf("/") + 1);
  if (lastSegment.startsWith("sid-")) {
    let returnString: string = lastSegment.trim().replace("sid-", "");
    if (returnString.includes("?")) {
      returnString = returnString.split("?")[0];
    }
    return returnString;
  } else {
    return url.searchParams.get("webid");
  }
}
function listingDetails() {
  async function initiate(staticId: string) {
    // set value of propertyId;
    if (staticId.length !== 0) {
      const url: URL = new URL(location.href);
      propertyId = staticId;
      // if webID is found in URL while static ID is also set, remove webID parameter from URL.
      if (urlWebId !== null) {
        let finalUrl: string = location.pathname;
        const params: URLSearchParams = new URLSearchParams(url.search);
        // remove webId and reattach rest of parameters.
        params.delete("webid");
        const paramString = params.toString();
        if (paramString.length !== 0) {
          finalUrl = finalUrl + "?" + paramString;
        }
        window.history.pushState({}, document.title, finalUrl);
      }
      const lastSegment: string = url
        .toString()
        .substring(url.toString().lastIndexOf("/") + 1);
      // if final sid-section is set in custom urls while stat ID is also set, remove final segtion of URL.
      if (lastSegment.startsWith("sid-")) {
        let finalUrl: string = location.pathname;
        finalUrl = finalUrl.slice(0, finalUrl.lastIndexOf("/"));
        finalUrl = finalUrl.slice(0, finalUrl.lastIndexOf("/"));
        const params: URLSearchParams = new URLSearchParams(url.search);
        // remove webId and reattach rest of parameters.
        const paramString = params.toString();
        if (paramString.length !== 0) {
          finalUrl = finalUrl + "?" + paramString;
        }
        window.history.pushState({}, document.title, finalUrl);
      }
    } else {
      propertyId = urlWebId!;
    }
    if (propertyId === null) {
      // check if propertyid is null if it is, do not initiate.
      state.value = "error";
      return;
    }
    currentListing.propertyId = propertyId;
    // initiate only if idle. Prevents multiple unneccessary initiations.
    if (state.value === "idle") {
      state.value = "sent";
      try {
        let surgaPropertyTmp: SurgaProperty = new SurgaProperty();
        surgaPropertyTmp.setupListing(
          await surgaSearch().searchSingleProperty(propertyId)
        );
        const addressTitle = surgaPropertyTmp.getAddressTitle();
        if (addressTitle.length > 0) {
          document.title = addressTitle;
          document
            .querySelector('meta[itemprop="name"]')
            .setAttribute("content", addressTitle);
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", addressTitle);
          document
            .querySelector('meta[name="twitter:title"]')
            .setAttribute("content", addressTitle);
        }
        if (surgaPropertyTmp.WebDescription.length > 0) {
          try {
            const metaDescription: string =
              surgaPropertyTmp.WebDescription.substring(0, 350);
            /*const metaTags = document.getElementsByTagName("meta");
            metaTags.namedItem("description").setAttribute("content", metaDescription);*/
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", metaDescription);
            document
              .querySelector('meta[itemprop="description"]')
              .setAttribute("content", metaDescription);
            document
              .querySelector('meta[property="og:description"]')
              .setAttribute("content", metaDescription);
            document
              .querySelector('meta[name="twitter:description"]')
              .setAttribute("content", metaDescription);
          } catch (e) {
            console.log("Please add SEO description for listing page.");
          }
        }
        currentListing.propertyDetails = surgaPropertyTmp;
        state.value = "ready";
      } catch (e) {
        state.value = "error";
      }
    }
  }
  function get() {
    function listingDetailsReactive() {
      return currentListing;
    }
    function stateReactive() {
      return state;
    }
    return { listingDetailsReactive, stateReactive };
  }
  return { initiate, get };
}
function listingDetailsUtil() {
  function get() {
    function listingProperty(propertyName: string) {
      let resultVal: string = returnStringResult(propertyName);
      if (resultVal == null) {
        console.log(
          "Listing property keyword not found. Please check setup. KEYWORD: '" +
          propertyName +
          "'"
        );
        resultVal = "";
      }
      return resultVal;
    }

    function returnStringResult(propertyName: string): string {
      let result: string = "";
      const lowerCaseName: string = propertyName.toLowerCase();
      switch (lowerCaseName) {
        case "listingtype": {
          result = manageListingTypeString(currentListing.propertyDetails["ListingType"]);
          break;
        }
        case "features": {
          result = manageFeaturesString();
          break;
        }
        case "rentmax":
        case "rentmin":
        case "pricemax":
        case "pricemin": {
          result = manageListingCurrency(propertyName);
          break;
        }
        case "rentrange": {
          result = manageRentRange();
          break;
        }
        case "rentrangeunit": {
          result = manageRentRangeUnit();
          break;
        }
        case "salerange": {
          result = manageSaleRange();
          break;
        }
        case "landarea": {
          result = manageLandArea();
          break;
        }
        case "bldarea": {
          result = manageBuildArea();
          break;
        }
        case "bldarealnddev": {
          result = manageBuildAreaLndDev();
          break;
        }
        case "salebldarea": {
          result = manageSaleBuildArea();
          break;
        }
        case "rentbldarea": {
          result = manageRentBuildArea();
          break;
        }
        case "leasecarspace": {
          result = manageLeaseCarSpace();
          break;
        }
        case "salecarspace": {
          result = manageSaleCarSpace();
          break;
        }
        case "outgoings": {
          result = manageOutgoings();
          break;
        }
        case "options": {
          result = manageOptions();
          break;
        }
        case "comments": {
          result = manageComments();
          break;
        }
        case "priceshowinplace": {
          result = managePriceShowInPlace();
          break;
        }
        case "rentshowinplace": {
          result = manageRentShowInPlace();
          break;
        }
        case "security": {
          result = manageSecurity();
          break;
        }
        case "cleaning": {
          result = manageCleaning();
          break;
        }
        default: {
          result = currentListing.propertyDetails[propertyName];
          if (result === null || result === undefined) {
            result = "";
          }
          result = result.trim();
        }
      }
      return result;
    }

    function manageFeaturesString(): string {
      let result: string = "";
      const surgaListingType: string =
        currentListing.propertyDetails["Features"];
      if (
        surgaListingType === null ||
        surgaListingType === undefined ||
        surgaListingType.trim() === ""
      ) {
        return "";
      }
      result += "<ul>";
      surgaListingType
        .split("|")
        .forEach((resultText) => (result += "<li>" + resultText + "</li>"));
      result += "</ul>";
      return result;
    }

    function manageListingCurrency(propertyName: string): string {
      // check if show in place string exists. If so display that.
      let showInPlacePropertyName: string = "";
      if (propertyName.startsWith("Rent")) {
        showInPlacePropertyName = "ShowInPlaceofRent";
      }
      if (propertyName.startsWith("Price")) {
        showInPlacePropertyName = "ShowInPlaceofPrice";
      }
      const showInPlace: string =
        currentListing.propertyDetails[showInPlacePropertyName];
      if (showInPlace && showInPlace !== "") {
        return showInPlace;
      }
      // check if currency should be displayed
      let displayPrice: boolean = true;
      if (propertyName.startsWith("Rent")) {
        displayPrice = currentListing.propertyDetails["DisplayRent"];
      }
      if (propertyName.startsWith("Price")) {
        displayPrice = currentListing.propertyDetails["DisplayPrice"];
      }
      if (
        displayPrice !== null &&
        displayPrice !== undefined &&
        !displayPrice
      ) {
        return "";
      }
      // return currency
      let result: string = "";
      const surgaListingCurrency: number =
        currentListing.propertyDetails[propertyName];
      if (surgaListingCurrency === null || surgaListingCurrency === undefined) {
        return "";
      }
      result = getLocaleCurrency(surgaListingCurrency);
      return result;
    }

    function manageSaleRange(): string {
      // determine if show in place if rent.
      const showInPlace: string =
        currentListing.propertyDetails["ShowInPlaceofPrice"];
      if (showInPlace && showInPlace !== "") {
        return showInPlace;
      }
      // determine if rent is to be displayed
      const displayPrice: boolean =
        currentListing.propertyDetails["DisplayPrice"];
      if (
        displayPrice !== null &&
        displayPrice !== undefined &&
        !displayPrice
      ) {
        return "";
      }
      // get price value
      if (isSaleProperty(currentListing.propertyDetails["ListingType"])) {
        const priceMin: number = currentListing.propertyDetails["PriceMin"];
        const priceMax: number = currentListing.propertyDetails["PriceMax"];
        return managePriceRange(priceMin, priceMax);
      }
      return "";
    }

    function manageRentRange(): string {
      // determine if show in place if rent.
      const showInPlace: string =
        currentListing.propertyDetails["ShowInPlaceofRent"];
      if (showInPlace && showInPlace !== "") {
        return showInPlace;
      }
      // determine if rent is to be displayed
      const displayRent: boolean =
        currentListing.propertyDetails["DisplayRent"];
      if (displayRent !== null && displayRent !== undefined && !displayRent) {
        return "";
      }
      // get price value
      if (isLeaseProperty(currentListing.propertyDetails["ListingType"])) {
        const priceMin: number = currentListing.propertyDetails["RentMin"];
        const priceMax: number = currentListing.propertyDetails["RentMax"];
        return managePriceRange(priceMin, priceMax, true);
      }
      return "";
    }

    function manageRentRangeUnit(): string {
      // determine if show in place if rent.
      const showInPlace: string =
        currentListing.propertyDetails["ShowInPlaceofRent"];
      if (showInPlace && showInPlace !== "") {
        return showInPlace;
      }
      // determine if rent is to be displayed
      const displayRent: boolean =
        currentListing.propertyDetails["DisplayRent"];
      if (displayRent !== null && displayRent !== undefined && !displayRent) {
        return "";
      }
      // get price value
      if (isLeaseProperty(currentListing.propertyDetails["ListingType"])) {
        const priceMin: number = currentListing.propertyDetails["RentPerUnitMin"];
        const priceMax: number = currentListing.propertyDetails["RentPerUnitMax"];
        const UOM: string = currentListing.propertyDetails["LandAreaUOM"];
        const rentPeriod: string = currentListing.propertyDetails["RentPeriod"];
        return managePriceRange(priceMin, priceMax, true, true, UOM, rentPeriod);
      }
      return "";
    }

    function manageLandArea(): string {
      let landArea: number = currentListing.propertyDetails[
        "LandAreaConverted"
      ] as number;
      if (landArea === null || landArea === undefined || landArea === 0) {
        return "";
      }
      const landUOM = getAreaUOM(currentListing.propertyDetails["LandAreaUOM"]);
      const landAreaString: string = landArea
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return landAreaString + " " + landUOM;
    }

    function manageBuildArea(): string {
      // get property type value
      const surgaListingType: string =
        currentListing.propertyDetails["ListingType"];
      // get build area unit
      const bldUOM = getAreaUOM(currentListing.propertyDetails["LandAreaUOM"]);
      // return sale build area.
      if (isSaleProperty(surgaListingType)) {
        const saleBldAreaString: string = manageBldAreaString(
          bldUOM,
          "SaleBldAreaMin",
          "SaleBldAreaMax"
        );
        if (saleBldAreaString !== "") {
          return saleBldAreaString;
        }
      }
      // return rent build area.
      if (isLeaseProperty(surgaListingType)) {
        const rentBldAreaString: string = manageBldAreaString(
          bldUOM,
          "RentBldAreaMin",
          "RentBldAreaMax"
        );
        if (rentBldAreaString !== "") {
          return rentBldAreaString;
        }
      }
      return "";
    }

    function manageSecurity(): string {
      // get property type value
      const surgaListingType: string =
        currentListing.propertyDetails["ListingType"];
      // return sale security.
      if (isSaleProperty(surgaListingType)) {
        const saleSecurity: string = currentListing.propertyDetails["SaleBrochureNLA"];
        if (saleSecurity !== null && saleSecurity !== "") {
          return saleSecurity;
        }
      }
      // return rent build area.
      if (isLeaseProperty(surgaListingType)) {
        const leaseSecurity: string = currentListing.propertyDetails["LeaseBrochureSecurity"];
        if (leaseSecurity !== null && leaseSecurity !== "") {
          return leaseSecurity;
        }
      }
      return "";
    }

    function manageBuildAreaLndDev(): string {
      // get property type value
      const surgaListingType: string =
        currentListing.propertyDetails["ListingType"];
      // get build area unit
      const bldUOM = getAreaUOM(currentListing.propertyDetails["LandAreaUOM"]);
      // return sale build area.
      if (isSaleProperty(surgaListingType)) {
        const saleBldAreaString: string = manageBldAreaString(
          bldUOM,
          "SaleBldAreaMin",
          "SaleBldAreaMax"
        );
        if (saleBldAreaString !== "") {
          if (!checkLandAndDev("SaleBldAreaMin", "SaleBldAreaMax")) {
            return "";
          }
          return saleBldAreaString;
        }
      }
      // return rent build area.
      if (isLeaseProperty(surgaListingType)) {
        const rentBldAreaString: string = manageBldAreaString(
          bldUOM,
          "RentBldAreaMin",
          "RentBldAreaMax"
        );
        if (rentBldAreaString !== "") {
          if (!checkLandAndDev("RentBldAreaMin", "RentBldAreaMax")) {
            return "";
          }
          return rentBldAreaString;
        }
      }
      return "";
    }

    // Check if Bld Area property should be displayed.
    function checkLandAndDev(bldAreaMin: string, bldAreaMax: string): boolean {
      let propertyType = currentListing.propertyDetails["PropertyType"];
      const landArea = currentListing.propertyDetails["LandArea"];
      if ((propertyType.includes("L") || propertyType.includes("D")) && landArea != 0) {
        if (currentListing.propertyDetails[bldAreaMin] == landArea || currentListing.propertyDetails[bldAreaMax] == landArea) {
          return false;
        }
      }
      return true;
    }
    function manageSaleBuildArea(): string {
      // get build area unit
      const bldUOM = getAreaUOM(currentListing.propertyDetails["bldAreaUOM"]);
      // return sale build area.
      if (isSaleProperty(currentListing.propertyDetails["ListingType"])) {
        const saleBldAreaString: string = manageBldAreaString(
          bldUOM,
          "SaleBldAreaMin",
          "SaleBldAreaMax"
        );
        if (saleBldAreaString !== "") {
          return saleBldAreaString;
        }
      }
      return "";
    }

    function manageRentBuildArea(): string {
      // get build area unit
      const bldUOM = getAreaUOM(currentListing.propertyDetails["bldAreaUOM"]);
      // return rent build area.
      if (isLeaseProperty(currentListing.propertyDetails["ListingType"])) {
        const rentBldAreaString: string = manageBldAreaString(
          bldUOM,
          "RentBldAreaMin",
          "RentBldAreaMax"
        );
        if (rentBldAreaString !== "") {
          return rentBldAreaString;
        }
      }
      return "";
    }

    function manageBldAreaString(
      bldUOM: string,
      minValueName: string,
      maxValueName: string
    ): string {
      const bldMin: number = currentListing.propertyDetails[minValueName];
      const bldMax: number = currentListing.propertyDetails[maxValueName];
      let bldString: string = manageLandRange(bldMin, bldMax, bldUOM);
      if (bldString !== "") {
        bldString += bldUOM;
      }
      return bldString;
    }

    function manageLeaseCarSpace() {
      // manage car space
      if (isLeaseProperty(currentListing.propertyDetails["ListingType"])) {
        const carSpace: number =
          currentListing.propertyDetails["LeaseCarSpace"];
        if (carSpace !== undefined && carSpace !== 0) {
          return carSpace as unknown as string;
        }
      }
      return "";
    }

    function manageSaleCarSpace() {
      // manage car space
      if (isSaleProperty(currentListing.propertyDetails["ListingType"])) {
        const carSpace: number = currentListing.propertyDetails["SaleCarSpace"];
        if (carSpace !== undefined && carSpace !== 0) {
          return carSpace as unknown as string;
        }
      }
      return "";
    }

    function manageOutgoings() {
      if (
        currentListing.propertyDetails["SaleBrochureOutgoings"] !== null &&
        isSaleProperty(currentListing.propertyDetails["ListingType"])
      ) {
        return currentListing.propertyDetails["SaleBrochureOutgoings"];
      }
      if (
        currentListing.propertyDetails["LeaseBrochureOutgoings"] !== null &&
        isLeaseProperty(currentListing.propertyDetails["ListingType"])
      ) {
        return currentListing.propertyDetails["LeaseBrochureOutgoings"];
      }
      return "";
    }

    function manageOptions() {
      if (
        currentListing.propertyDetails["SaleBrochureListPrice"] !== null &&
        isSaleProperty(currentListing.propertyDetails["ListingType"])
      ) {
        return currentListing.propertyDetails["SaleBrochureListPrice"];
      }
      if (
        currentListing.propertyDetails["LeaseBrochureOption"] !== null &&
        isLeaseProperty(currentListing.propertyDetails["ListingType"])
      ) {
        return currentListing.propertyDetails["LeaseBrochureOption"];
      }
      return "";
    }

    function manageComments() {
      if (
        currentListing.propertyDetails["SaleBrochureComment"] !== null &&
        isSaleProperty(currentListing.propertyDetails["ListingType"])
      ) {
        return currentListing.propertyDetails["SaleBrochureComment"];
      }
      if (
        currentListing.propertyDetails["LeaseBrochureComments"] !== null &&
        isLeaseProperty(currentListing.propertyDetails["ListingType"])
      ) {
        return currentListing.propertyDetails["LeaseBrochureComments"];
      }
      return "";
    }

    function managePriceShowInPlace(): string {
      const showInPlace: string =
        currentListing.propertyDetails["ShowInPlaceofPrice"];
      if (showInPlace && showInPlace !== "") {
        return showInPlace;
      } else {
        return "";
      }
    }

    function manageCleaning(): string {
      const leaseCleaning: string =
        currentListing.propertyDetails["LeaseCleaning"];
      if (leaseCleaning && leaseCleaning !== "") {
        return leaseCleaning;
      } else {
        return "";
      }
    }

    function manageRentShowInPlace(): string {
      const showInPlace: string =
        currentListing.propertyDetails["ShowInPlaceofRent"];
      if (showInPlace && showInPlace !== "") {
        return showInPlace;
      } else {
        return "";
      }
    }
    return { listingProperty };
  }
  return { get };
}
export { listingDetails, listingDetailsUtil };
