import { reactive } from "vue";
import { SearchDataModel } from "./models/search/searchDataModel";
import { SearchResultModel } from "./models/search/searchResultModel";
import { RecommendedSearchModel } from "./models/search/recommendedSearchModel";
import { stateStorage } from "./stateStorageComp";
import { globalValues } from "./globalValuesComp";

const searchDataObjectStore = "search-data-object";
let searchDataModel: SearchDataModel = new SearchDataModel();
const recommendState = reactive({
  recommendationData: {} as RecommendedSearchModel
});
function searchHelperComp() {
  function initiate() {
    if (searchDataModel.setupProgress !== "complete") {
      searchDataModel.setupProgress = "start";
    }
    recommendState.recommendationData = searchDataModel.recommendedSearch;
  }
  function resetRecommended() {
    searchDataModel.resetRecommendedSearch();
    searchDataModel.setupProgress = "start";
    recommendState.recommendationData = searchDataModel.recommendedSearch;
  }
  function localStore() {
    function save() {
      stateStorage(searchDataObjectStore).set(searchDataModel);
    }
    function retrieve() {
      const searchDataObject = stateStorage(searchDataObjectStore).get()
        .value as Object;
      searchDataModel.JSONcopy(searchDataObject);
    }
    return { save, retrieve };
  }

  async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function setupSearchSettings(): Promise<void> {
    while (searchDataModel.setupProgress === "initiated") {
      await delay(100);
    }
    if (searchDataModel.setupProgress === "start") {
      searchDataModel.setupProgress = "inprogress";
      try {
        for (let region of searchDataModel.rawRegionObject) {
          if (region.state) {
            add().state(region.state);
            recommendations().add().state(region.state, region.listingtypes);
          }
        }
      } catch (ex) {
        // incase there is only 1 region name, run this instead.
        /*const region = xmlObject.Regions.RegionName;
      if (region.state) {
        searchHelperComp().add().state(region.state);
        searchHelperComp()
          .recommendations()
          .add()
          .state(region.state, region.listingtypes);
      }*/
      }
      for (let address of searchDataModel.rawAddressObject) {
        add().street(address.StreetName);
        add().siteName(address.SiteName);
        recommendations()
          .add()
          .address(
            address.SiteName,
            address.StreetName,
            address.SuburbName,
            address.CityName,
            address.PostCode,
            address.ListingType
          );
      }
      const isRelaxed = setIsRelaxed();
      searchDataModel.recommendedSearch.setupSearchSettings(isRelaxed);
      for (let suburb of searchDataModel.rawSuburbObject) {
        if (suburb.postcode) {
          add().postcode(suburb.postcode);
        }
        if (suburb._parameter) {
          add().suburb(suburb._parameter);
        }
        // add suburb to recommendations.
        if (suburb._parameter && suburb.listingtype && suburb.postcode) {
          recommendations()
            .add()
            .suburb(
              suburb._parameter,
              suburb.postcode,
              suburb.state,
              suburb.listingtype
            );
        }
      }
      recommendations().sort();
      searchDataModel.setupProgress = "complete";
      searchDataModel.clearRawObjects();
      localStore().save();
    }
  }

  function setIsRelaxed(): boolean {
    const notRelaxedString: string = globalValues().get(
      "notRelaxedSearch"
    );
    if (notRelaxedString === "True") {
      return false;
    } else {
      return true;
    }
  }

  function recommendations() {
    function getState() {
      return recommendState;
    }
    function add() {
      function state(state: string, listingType: string) {
        if (state && listingType) {
          searchDataModel.recommendedSearch.addState(state, listingType);
        }
      }
      function suburb(
        suburbName: string,
        postcode: string,
        state: string,
        listingType: string
      ): void {
        if (suburbName && listingType) {
          searchDataModel.recommendedSearch.addSuburb(
            suburbName,
            postcode,
            state,
            listingType
          );
        }
      }
      function address(
        siteName: string,
        streetName: string,
        suburbName: string,
        cityName: string,
        postcode: string,
        listingType: string
      ) {
        let searchResult: SearchResultModel = new SearchResultModel();
        searchResult.postcode = postcode;
        searchResult.siteName = siteName;
        searchResult.street = streetName;
        searchResult.suburb = suburbName;
        searchResult.city = cityName;
        searchDataModel.recommendedSearch.addAddress(searchResult, listingType);
      }
      return { suburb, address, state };
    }
    function sort() {
      searchDataModel.recommendedSearch.sort();
    }
    return { add, sort, getState };
  }
  function add() {
    function rawRegion(rawRegionObject: Record<string, string>[]) {
      searchDataModel.rawRegionObject = rawRegionObject;
    }
    function rawSuburb(rawSuburbObject: Record<string, string>[]) {
      searchDataModel.rawSuburbObject = rawSuburbObject;
    }
    function rawAddress(rawAddressObject: Record<string, string>[]) {
      searchDataModel.rawAddressObject = rawAddressObject;
    }
    function state(stateName: string): void {
      stateName = sanitize(stateName);
      if (
        stateName &&
        stateName !== "" &&
        searchDataModel.allAddressData.states.indexOf(stateName) === -1
      ) {
        searchDataModel.allAddressData.states.push(stateName);
      }
    }
    function suburb(suburbName: string): void {
      suburbName = sanitize(suburbName);
      if (
        suburbName &&
        suburbName !== "" &&
        searchDataModel.allAddressData.suburbs.indexOf(suburbName) === -1
      ) {
        searchDataModel.allAddressData.suburbs.push(suburbName);
      }
    }
    function street(streetName: string): void {
      streetName = sanitize(streetName);
      if (
        streetName &&
        streetName !== "" &&
        searchDataModel.allAddressData.streets.indexOf(streetName) === -1
      ) {
        searchDataModel.allAddressData.streets.push(streetName);
      }
    }
    function siteName(siteName: string): void {
      siteName = sanitize(siteName);
      if (
        siteName &&
        siteName !== "" &&
        searchDataModel.allAddressData.siteName.indexOf(siteName) === -1
      ) {
        searchDataModel.allAddressData.siteName.push(siteName);
      }
    }
    function postcode(postcode: string): void {
      postcode = sanitize(postcode);
      if (
        postcode &&
        postcode !== "" &&
        searchDataModel.allAddressData.postcodes.indexOf(postcode) === -1
      ) {
        searchDataModel.allAddressData.postcodes.push(postcode);
      }
    }
    return {
      suburb,
      street,
      siteName,
      postcode,
      state,
      rawRegion,
      rawAddress,
      rawSuburb
    };
  }
  function get() {
    function searchData(): SearchDataModel {
      return searchDataModel;
    }
    return { searchData };
  }
  async function search(searchText: string): Promise<SearchResultModel> {
    if (searchText.trim() !== "") {
      // wait for search recommendations to be retrieved from XML and search settings to be setup before continueing.
      await setupSearchSettings();
    } else {
      return new SearchResultModel();
    }
    searchText = sanitize(searchText);
    let finalResult = await searchRecommended(searchText);
    if (!finalResult) {
      finalResult = keywordSearch(searchText);
    }
    return finalResult;
  }

  function keywordSearch(searchText: string): SearchResultModel {
    let sortDto = new SortDTO();
    searchDataModel.setSearchText(searchText);
    do {
      sortDto.sortParameter = "";
      sortDto.sortFinalValue = [0, ""];
      sortDto.sortResult = searchDataModel.sortSuburbs();
      manageSort(sortDto, "suburb");
      if (!sortDto.finalResult.street) {
        sortDto.sortResult = searchDataModel.sortStreets();
        manageSort(sortDto, "street");
      }
      if (!sortDto.finalResult.postcode) {
        sortDto.sortResult = searchDataModel.sortPostcodes();
        manageSort(sortDto, "postcode");
      }
      if (!sortDto.finalResult.state) {
        sortDto.sortResult = searchDataModel.sortState();
        manageSort(sortDto, "state");
      }
      if (!sortDto.finalResult.siteName) {
        sortDto.sortResult = searchDataModel.sortSiteName();
        manageSort(sortDto, "siteName");
      }
      if (sortDto.sortParameter !== "") {
        if (sortDto.sortParameter == "suburb") {
          sortDto.finalResult.AddSuburbMultiple(sortDto.sortFinalValue[1] as string);
        } else {
          sortDto.finalResult[sortDto.sortParameter] = sortDto
          .sortFinalValue[1] as string;
        }
        searchDataModel.setSearchText(
          searchDataModel.searchText.replace(
            sortDto.sortFinalValue[1] as string,
            "||"
          )
        );
      }
    } while (sortDto.sortParameter !== "");
    sortDto.finalResult.searchText = setupFreeText(
      searchDataModel.searchText,
      searchText
    );
    return sortDto.finalResult;
  }

  function setupFreeText(searchText: string, originalText: string): string {
    /*for (let sectionText of searchText.split("||")) {
      let testText = sectionText.replace(/[^A-Za-z0-9\s/-]/g, "").trim();
      if (testText.length > 0) {
        // fix later if surga upgrades.
        // return testText;
        return originalText;
      }
    }*/
    if (searchText == originalText) {
      return originalText;
    }
    return "";
  }

  function manageSort(sortDto: SortDTO, parameterName: string) {
    if (
      sortDto.sortResult &&
      (sortDto.sortResult[0] as number) > (sortDto.sortFinalValue[0] as number)
    ) {
      sortDto.sortParameter = parameterName;
      sortDto.sortFinalValue = sortDto.sortResult;
    }
  }

  async function searchRecommended(
    searchText: string
  ): Promise<SearchResultModel | null> {
    let recommendResult: SearchResultModel =
      await searchDataModel.recommendedSearch.findRecommendationResultById(
        searchText
      );
    return recommendResult;
  }
  function sanitize(inputString: string) {
    if (inputString) {
      inputString = inputString.toLowerCase().trim();
      return inputString;
    }
    return "";
  }
  return {
    add,
    get,
    initiate,
    resetRecommended,
    search,
    recommendations,
    localStore,
    setupSearchSettings
  };
}
export { searchHelperComp };

class SortDTO {
  public sortParameter: string;
  public sortResult: (string | number)[];
  public sortFinalValue: (string | number)[];
  public finalResult: SearchResultModel;
  public parameterName: string;
  constructor() {
    this.sortParameter = "";
    this.sortResult = [0, ""];
    this.sortFinalValue = [0, ""];
    this.finalResult = new SearchResultModel();
  }
}
